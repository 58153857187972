import { createTheme } from "@mui/material/styles";

const dark = "#171717";
const blue = "#22358e";
const darkerGrey = "#262626";
const lightGrey = "#d8d8d8";
const skyBlue = "#3fa0d9";

export const theme = createTheme({
  palette: {
    common: {
      dark,
      blue,
      darkerGrey,
      lightGrey,
      skyBlue,
    },
    primary: {
      main: dark,
    },
    secondary: {
      main: skyBlue,
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(", "),
    fontSize: 10,
    htmlFontSize: 10,
    h1: {
      fontSize: "3.5rem",
      color: dark,
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.5rem",
      color: dark,
      fontWeight: 600,
    },
    h3: {
      fontSize: "2.25rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.85rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },

    body1: {
      fontSize: "1.6rem",
      fontWeight: 400,
      lineHeight: 1.7,
      color: dark,
    },
    body2: {
      fontSize: "1.4rem",
      fontWeight: 400,
      lineHeight: 1.85,
    },

    input: {
      width: "100%",
      border: "1px solid",
      borderRadius: ".5rem",
      padding: "1rem",
      fontSize: "1.6rem",
      color: dark,
      fontWeight: 600,

      "&:focus": {
        outline: "none",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
        },
      },
    },
  },
});
