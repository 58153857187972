import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "menuId" && prop !== "selectedMenu",
})(({ theme, menuId, selectedMenu }) => ({
  background:
    menuId === selectedMenu ? theme.palette.secondary.light : "transparent",
  borderRadius: menuId === selectedMenu ? 5 : 0,
}));

const AsideListItem = (props) => {
  const { menu, selectedMenu, menuSlideIn } = props;
  return (
    <StyledListItemButton
      disableRipple
      style={{ justifyContent: "center" }}
      component={Link}
      to={menu.path}
      menuId={menu.id}
      selectedMenu={selectedMenu}
    >
      {!menuSlideIn ? <ListItemText>{menu.title}</ListItemText> : null}
    </StyledListItemButton>
  );
};

export default AsideListItem;
