export const menus = [
  { id: 0, title: "Dashboard", path: "/dashboard" },
  {
    id: 1,
    title: "Apps",
    path: "/dashboard/apps",
  },
  {
    id: 2,
    title: "Wallets",
    path: "/dashboard/wallets",
  },
  {
    id: 3,
    title: "Transactions",
    path: "/dashboard/transactions",
  },
  {
    id: 4,
    title: "Customers",
    path: "/dashboard/customers",
  },
  {
    id: 5,
    title: "Settlements",
    path: "/dashboard/settlements",
  },
  {
    id: 6,
    title: "Audit Logs",
    path: "/dashboard/audit-logs",
  },
];
