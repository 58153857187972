import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import RectangularCard from "components/dashboard/RectangularCard";
import AnalyticsCard from "components/dashboard/AnalyticsCard";

const Container = styled(Grid)(({ theme }) => ({
  padding: "1rem 2rem 5rem",

  [theme.breakpoints.down("sm")]: {
    paddingTop: "3rem",
  },
}));

const RectangularCardWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "menuSlideIn",
})(({ theme, menuSlideIn }) => ({
  padding: "2rem 0 5rem 0",
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridGap: "2rem",

  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [theme.breakpoints.only("sm")]: {
    gridTemplateColumns: menuSlideIn ? "repeat(2, 1fr)" : "1fr",
  },

  [theme.breakpoints.only("xs")]: {
    gridTemplateColumns: "1fr",
  },
}));

const AnalyticsCardWrapper = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridGap: "2rem",

  [theme.breakpoints.only("sm")]: {
    gridTemplateColumns: "1fr",
  },

  [theme.breakpoints.only("xs")]: {
    gridTemplateColumns: "1fr",
  },
}));

const Dashboard = ({ menuSlideIn }) => {
  return (
    <Container container direction="column">
      <Grid item mb={1}>
        <Typography variant="body1" style={{ fontSize: "1.5rem" }}>
          <span style={{ fontWeight: 600 }}>Welcome</span>, Mishael
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          style={{ fontSize: "1.5rem", fontWeight: 600 }}
        >
          Dashboard
        </Typography>
      </Grid>
      <RectangularCardWrapper item container menuSlideIn={menuSlideIn}>
        <Grid item>
          <RectangularCard title="Total Apps" count={10} />
        </Grid>
        <Grid item>
          <RectangularCard title="Total Processors" count={28} />
        </Grid>
        <Grid item>
          <RectangularCard title="Total Customers" count={150} />
        </Grid>
        <Grid item>
          <RectangularCard title="Total Transactions" count={350} />
        </Grid>
      </RectangularCardWrapper>
      <AnalyticsCardWrapper container>
        <Grid item>
          <AnalyticsCard label="Analytics for number of transactions" />
        </Grid>
        <Grid item>
          <AnalyticsCard label="Analytics for processor usage" />
        </Grid>
      </AnalyticsCardWrapper>
    </Container>
  );
};

export default Dashboard;
