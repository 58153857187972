import React, { Fragment } from 'react'
import Navigator from '../navigator'

const Layout = () => {
  return (
    <Fragment>
        <Navigator />
    </Fragment>
  )
}

export default Layout