import React from "react";
import List from "@mui/material/List";
import AsideListItem from "./AsideListItem";
import { menus } from "lib/dataset/menus";
import PropTypes from "prop-types";

const AsideList = (props) => {
  const { menuSlideIn, selectedMenu } = props;
  return (
    <List sx={{ width: "100%" }} component="nav">
      {menus.map((menu) => (
        <AsideListItem
          key={menu.id}
          menu={menu}
          menuSlideIn={menuSlideIn}
          selectedMenu={selectedMenu}
        />
      ))}
    </List>
  );
};

AsideList.propTypes = {
  menuSlideIn: PropTypes.bool.isRequired,
  selectedMenu: PropTypes.number.isRequired,
  setSelectedMenu: PropTypes.func.isRequired,
};

export default AsideList;
