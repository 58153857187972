import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CustomFormInput from "components/shared/FormInput/CustomFormInput";
import { CustomButton } from "components/shared/Button/CustomButton";
import Logo from "assets/image1.png";

const Container = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",

  [theme.breakpoints.down("sm")]: {
    padding: "1rem 2rem",
  },
}));

const Wrapper = styled(Grid)(({ theme }) => ({
  width: 400,
  background: "#fff",
  padding: "5rem 2rem",
  borderRadius: 10,
  boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StyledLogo = styled("img")(({ theme }) => ({
  width: 120,
}));

const ResetPassword = () => {
  const [formState, setFormState] = useState({
    otp: "",
    new_password: "",
    comfirm_password: "",
  });

  const { otp, new_password, confirm_password } = formState;

  const handleChange = (e) => {
    setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();

    navigate("/login");
  };

  return (
    <Container
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Wrapper item container justifyContent="center">
        <Grid item container justifyContent="center">
          <StyledLogo src={Logo} alt="Logo" />
        </Grid>
        <Grid item mb={2}>
          <Typography variant="h4" align="center">
            Reset Password
          </Typography>
        </Grid>

        <Grid
          container
          direction="column"
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid item>
            <CustomFormInput
              type="otp"
              name="otp"
              placeholder="OTP"
              value={otp}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <CustomFormInput
              type="new_password"
              name="new_password"
              placeholder="New Password"
              value={new_password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <CustomFormInput
              type="confirm_password"
              name="confirm_password"
              placeholder="Confirm Password"
              value={confirm_password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item container mt={3}>
            <CustomButton type="submit" style={{ width: "100%" }}>
              Submit
            </CustomButton>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default ResetPassword;
