import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "components/shared/Button/CustomButton";
import CustomFormInput from "components/shared/FormInput/CustomFormInput";
import Logo from "assets/image1.png";

const Container = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",

  [theme.breakpoints.down("sm")]: {
    padding: "1rem 2rem",
  },
}));

const Wrapper = styled(Grid)(({ theme }) => ({
  width: 400,
  background: "#fff",
  padding: "5rem 2rem",
  borderRadius: 10,
  boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StyledLogo = styled("img")(({ theme }) => ({
  width: 120,
}));

const ForgotPassword = () => {
  const [formState, setFormState] = useState({
    email: "",
  });

  const { email } = formState;

  const handleChange = (e) => {
    const getValue = { ...formState };
    getValue[e.target.name] = e.target.value;
    setFormState(getValue);
  };

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    navigate("/reset-password");
  };

  return (
    <Container
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Wrapper item container justifyContent="center">
        <Grid item container justifyContent="center">
          <StyledLogo src={Logo} alt="Logo" />
        </Grid>
        <Grid item mb={2}>
          <Typography variant="h4" align="center">
            Forgot Password
          </Typography>
        </Grid>

        <Grid
          container
          direction="column"
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid item>
            <CustomFormInput
              type="email"
              name="email"
              placeholder="Email address"
              value={email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item container mt={3}>
            <CustomButton type="submit" style={{ width: "100%" }}>
              Proceed
            </CustomButton>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default ForgotPassword;
