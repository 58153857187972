import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: 180,
  fontSize: "1.6rem",
  fontWeight: 400,
  textTransform: "none",
  //   borderRadius: 0,
  background: theme.palette.secondary.main,
  color: "#fff",
  borderRadius: 5,

  "&:hover": {
    background: theme.palette.secondary.light,
  },

  "&:active": {
    background: theme.palette.secondary.dark,
  },

  "&:disabled": {
    cursor: "not-allowed",
    pointerEvents: "all !important",
    background: theme.palette.common.lightGrey,
    color: "#fff",
  },
}));
