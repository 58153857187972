import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Container = styled(Grid)({
  boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
  padding: "2rem 5rem",
  borderRadius: 5,
  minHeight: 400,
});

const AnalyticsCard = ({ label }) => {
  return (
    <Container
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography align="center">{label}</Typography>
      </Grid>
    </Container>
  );
};

export default AnalyticsCard;
