import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LockIcon from "@mui/icons-material/Lock";
import { useTheme, styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";

import Logo from "assets/image1.png";
import { CustomButton } from "components/shared/Button/CustomButton";
import CustomFormInput from "components/shared/FormInput/CustomFormInput";

const Container = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",

  [theme.breakpoints.down("sm")]: {
    padding: "1rem 2rem",
  },
}));

const Wrapper = styled(Grid)(({ theme }) => ({
  width: 400,
  background: "#fff",
  padding: "5rem 2rem",
  borderRadius: 10,
  boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StyledLogo = styled("img")(({ theme }) => ({
  width: 120,
}));

const ForgotPasswordWrapper = styled(Grid)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
}));

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formState;

  const handleChange = (e) => {
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    navigate("/dashboard");
  };

  return (
    <Container
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Wrapper item container justifyContent="center">
        <Grid item container justifyContent="center">
          <StyledLogo src={Logo} alt="Logo" />
        </Grid>
        <Grid item mb={2}>
          <Typography variant="h4" align="center">
            Sign-in to your account
          </Typography>
        </Grid>

        <Grid
          container
          direction="column"
          component="form"
          onSubmit={handleLogin}
        >
          <Grid item mb={1}>
            <CustomFormInput
              type="email"
              name="email"
              placeholder="Email address"
              value={email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={handleChange}
            />
          </Grid>
          <ForgotPasswordWrapper
            item
            container
            alignItems="center"
            component={Link}
            to="/forgot-password"
          >
            <LockIcon />
            <Typography variant="body2" ml={1}>
              Forgot password?
            </Typography>
          </ForgotPasswordWrapper>
          <Grid item container mt={3}>
            <CustomButton type="submit" style={{ width: "100%" }}>
              Sign in
            </CustomButton>
          </Grid>
          <Grid item container justifyContent="center" mt={2}>
            <Typography variant="body2">
              New to MishTranzact?{" "}
              <Link
                to="/register"
                style={{
                  textDecoration: "none",
                  color: theme.palette.common.skyBlue,
                }}
              >
                <span> Create an account</span>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default Login;
