import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    padding: ".75rem 1.5rem",
    cursor: "pointer",
    textAlign: "left",
    whiteSpace: "normal",
    textDecoration: "none",
    display: "block",
    "&:hover": {
      background: "#f4f4f4",
    },

    "&:before": {
      content: "none",
      borderTop: "none",
    },
    "&:after": {
      content: "none",
      borderTop: "none",
    },

    "& > .MuiListItemText-root": {
      flex: 1,
      "& > .MuiTypography-root": {
        fontSize: "1.5rem",
        color: theme.palette.secondary.main,
      },
    },
  },
}));

const ProfileMenuList = ({ open, anchorEl, setAnchorEl }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    navigate("/");
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account"
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflowY: "auto",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          minWidth: 250,
          maxWidth: 270,
          minHeight: 200,
          maxHeight: 400,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
          "&::-webkit-scrollbar": {
            width: ".85rem",
          },

          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 1rem rgba(0, 0, 0, 0.2)",
          },

          "&::-webkit-scrollbar-thumb": {
            borderRadius: ".5rem",
            background: theme.palette.common.lightGrey,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      MenuListProps={{
        "aria-labelledby": "account",
      }}
    >
      <StyledMenuItem
        component={Link}
        to="/dashboard/settings"
        onClick={handleClose}
      >
        <ListItemText>Settings</ListItemText>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleLogout}>
        <ListItemText>Logout</ListItemText>
      </StyledMenuItem>
    </Menu>
  );
};

export default ProfileMenuList;
