import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const Container = styled(Grid)(({ theme }) => ({
  padding: "1rem 2rem 5rem",

  [theme.breakpoints.down("sm")]: {
    paddingTop: "3rem",
  },
}));

const Settlements = () => {
  return <Container>Settlements</Container>;
};

export default Settlements;
