import React from "react";
import { Aside } from "./AsideNavigationStyles";
import AsideList from "./AsideList";

const AsideNavigation = (props) => {
  const { menuSlideIn, selectedMenu } = props;

  return (
    <Aside menuSlideIn={menuSlideIn}>
      <AsideList menuSlideIn={menuSlideIn} selectedMenu={selectedMenu} />
    </Aside>
  );
};

export default AsideNavigation;
