import React, { Fragment } from "react";
import { FormInput, FormLabel, Error } from "./styles";

const CustomFormInput = (props) => {
  const {
    labelId,
    label,
    type,
    placeholder,
    error,
    labelColor,
    onChange,
    ...rest
  } = props;
  return (
    <Fragment>
      <FormLabel htmlFor={labelId} labelColor={labelColor}>
        {label}
      </FormLabel>
      <FormInput
        type={type}
        id={labelId}
        placeholder={placeholder}
        autoComplete="off"
        onChange={onChange}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Fragment>
  );
};

export default CustomFormInput;
