import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import { CustomButton } from "components/shared/Button/CustomButton";
import CustomFormInput from "components/shared/FormInput/CustomFormInput";
import Logo from "../../../assets/image1.png";
import { validateEmail } from "lib/helpers";
import { registerUser } from "services/auth/register";

const Container = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",
  padding: "2rem 5rem",

  [theme.breakpoints.down("sm")]: {
    padding: "1rem 2rem",
  },
}));

const Wrapper = styled(Grid)(({ theme }) => ({
  width: 400,
  background: "#fff",
  padding: "2rem",
  borderRadius: 10,
  boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StyledLogo = styled("img")(({ theme }) => ({
  width: 120,
}));

const Register = () => {
  const theme = useTheme();

  const [formState, setFormState] = useState({
    business_name: "",
    first_name: "",
    last_name: "",
    email: "",
    tel: "",
    password: "",
    confirm_password: "",
    address: "",
    state: "",
    country: "",
    city: "",
  });

  const [businessNameError, setBusinessNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const {
    business_name,
    first_name,
    last_name,
    email,
    tel,
    password,
    confirm_password,
    address,
    state,
    country,
    city,
  } = formState;

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (!business_name) {
      setBusinessNameError("Business name is required.");
      return;
    }

    if (!first_name) {
      setFirstNameError("First name is required.");
      return;
    }

    if (!last_name) {
      setLastNameError("Last name is required.");
      return;
    }

    if (!email || !validateEmail(email)) {
      setEmailError("Enter a valid email address.");
      return;
    }

    if (!tel) {
      setPhoneNumberError("Enter a phone number");
      return;
    }

    if (!password) {
      setPasswordError("Password is required");
      return;
    }

    if (password !== confirm_password) {
      setPasswordError("Passwords do not match");
      return;
    }

    setIsLoading(true);

    try {
      const { status, data } = await registerUser(
        "https://payment.mishtranzact.com/api/v1/auth/register",
        {
          businessName: business_name,
          firstName: first_name,
          lastName: last_name,
          email: email,
          phoneNumber: tel,
          password: password,
          confirmPassword: confirm_password,
          address: address,
          city,
          state,
          country,
        }
      );

      if (status === 201 || data.status === true) {
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  return (
    <Container
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Wrapper item container direction="column" alignItems="center">
        <Grid item container justifyContent="center">
          <StyledLogo src={Logo} alt="Logo" />
        </Grid>
        <Grid item mb={2}>
          <Typography variant="h4" align="center">
            Sign up
          </Typography>
        </Grid>

        <Grid item mb={2}>
          <Typography variant="body1">
            Create your MishTranzact Account
          </Typography>
        </Grid>

        <Grid
          container
          direction="column"
          component="form"
          onSubmit={handleRegister}
        >
          <Grid item mb={1}>
            <CustomFormInput
              type="text"
              name="business_name"
              placeholder="Business Name"
              value={business_name}
              onChange={handleChange}
              error={businessNameError}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="text"
              name="first_name"
              placeholder="First Name"
              value={first_name}
              onChange={handleChange}
              error={firstNameError}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={last_name}
              onChange={handleChange}
              error={lastNameError}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="text"
              name="email"
              placeholder="Email address"
              value={email}
              onChange={handleChange}
              error={emailError}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="tel"
              name="tel"
              placeholder="Phone Number"
              value={tel}
              onChange={handleChange}
              error={phoneNumberError}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={handleChange}
              error={passwordError}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="password"
              name="confirm_password"
              placeholder="Confirm password"
              value={confirm_password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="text"
              name="address"
              placeholder="Address"
              value={address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="text"
              name="city"
              placeholder="City"
              value={city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="text"
              name="state"
              placeholder="State"
              value={state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item mb={1}>
            <CustomFormInput
              type="text"
              name="country"
              placeholder="Country"
              value={country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item container mt={3}>
            <CustomButton type="submit" style={{ width: "100%" }}>
              {isLoading ? (
                <CircularProgress
                  style={{ width: 25, height: 25, color: "#fff" }}
                />
              ) : (
                "Register"
              )}
            </CustomButton>
          </Grid>
          <Grid item container justifyContent="center" mt={2}>
            <Typography variant="body2">
              Already have an account?{" "}
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: theme.palette.common.skyBlue,
                }}
              >
                <span> Sign in</span>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default Register;
