import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Container = styled(Grid)({
  boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
  padding: "2rem 5rem",
  borderRadius: 5,
});

const RectangularCard = ({ title, count }) => {
  return (
    <Container container direction="column" alignItems="center">
      <Grid item mb={2}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" align="center">
          {count}
        </Typography>
      </Grid>
    </Container>
  );
};

export default RectangularCard;
