import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Register from "../pages/auth/Register";
import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Dashboard from "pages/Dashboard";
import DashboardLayout from "layouts/dashboard/DashboardLayout";
import Apps from "pages/Apps";
import Wallets from "pages/wallets";
import Transactions from "pages/Transactions";
import Customers from "pages/Customers";
import Settlements from "pages/Settlements";
import AuditLogs from "pages/AuditLogs";

const Navigator = () => {
  return (
    <Suspense fallback={"loading..."}>
      <Routes>
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />

        <Route exact path="/" element={<Navigate replace to="/login" />} />
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="apps" element={<Apps />} />
          <Route path="wallets" element={<Wallets />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="customers" element={<Customers />} />
          <Route path="settlements" element={<Settlements />} />
          <Route path="audit-logs" element={<AuditLogs />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Navigator;
