import React, { Fragment, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Outlet, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import Header from "layouts/header/Header";
import AsideNavigation from "layouts/aside/AsideNavigation";
import { menus } from "lib/dataset/menus";

const OutletContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "menuSlideIn",
})(({ theme, menuSlideIn }) => ({
  transition: "all .25s ease-in-out",
  paddingLeft: 270,
  overflowX: "hidden",
  paddingTop: "7.5rem",

  [theme.breakpoints.only("sm")]: {
    paddingLeft: menuSlideIn ? 0 : 270,
  },

  [theme.breakpoints.only("xs")]: {
    paddingLeft: menuSlideIn ? 0 : "80%",
  },
}));

const DashboardLayout = () => {
  const theme = useTheme();

  const [menuSlideIn, setMenuSlideIn] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(0);

  const { pathname } = useLocation();

  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (matchesSM) {
      setMenuSlideIn(true);
    } else {
      setMenuSlideIn(false);
    }
  }, [matchesSM]);

  useEffect(() => {
    const menuList = [...menus];

    menuList.forEach((menu) => {
      switch (pathname) {
        case menu.path:
          if (selectedMenu !== menu.id) {
            setSelectedMenu(menu.id);
          }
          break;
        default:
          break;
      }
    });
  }, [selectedMenu, pathname]);

  return (
    <Fragment>
      <Header menuSlideIn={menuSlideIn} setMenuSlideIn={setMenuSlideIn} />
      <AsideNavigation selectedMenu={selectedMenu} menuSlideIn={menuSlideIn} />
      <OutletContainer menuSlideIn={menuSlideIn}>
        <Outlet />
      </OutletContainer>
      {/* <footer>Footer</footer> */}
    </Fragment>
  );
};

export default DashboardLayout;
