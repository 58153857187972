import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ProfileMenuList from "./ProfileMenuList";
import Logo from "assets/image1.png";

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  padding: ".5rem 2rem .5rem 0",
  zIndex: theme.zIndex.appBar + 2,

  [theme.breakpoints.only("xs")]: {
    padding: "1rem 1rem 1rem 0",
  },
}));

export const StyledLogo = styled("img")(({ theme }) => ({
  width: 200,

  [theme.breakpoints.only("xs")]: {
    width: 150,
    display: "block",
    marginBottom: "1rem",
  },
}));

export const ProfileIconButton = styled(IconButton)({
  width: 30,
  height: 30,
  overflow: "hidden",
  background: "#bbb",
  padding: 0,
});

const Header = ({ menuSlideIn, setMenuSlideIn }) => {
  const [openProfileMenu, setOpenProfileMenu] = useState(null);
  const open = Boolean(openProfileMenu);

  const handleClick = (event) => {
    setOpenProfileMenu(event.currentTarget);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledAppBar position="fixed">
        <Toolbar
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
          }}
        >
          <Box sx={{ mr: { xs: 0, sm: "auto" } }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={Logo} alt="Logo" style={{ width: 70 }} />
            </Link>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              {menuSlideIn ? (
                <IconButton onClick={() => setMenuSlideIn(false)}>
                  <MenuIcon color="primary" sx={{ fontSize: "2.5rem" }} />
                </IconButton>
              ) : (
                <IconButton onClick={() => setMenuSlideIn(true)}>
                  <CloseIcon color="primary" sx={{ fontSize: "2.5rem" }} />
                </IconButton>
              )}
            </Box>
            <Box>
              <ProfileIconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
                id="account"
              >
                <Typography variant="body1">DP</Typography>
              </ProfileIconButton>
            </Box>
          </Box>
        </Toolbar>
        <ProfileMenuList
          anchorEl={openProfileMenu}
          setAnchorEl={setOpenProfileMenu}
          open={open}
        />
      </StyledAppBar>
    </Box>
  );
};

export default Header;
