import { styled } from "@mui/material/styles";

export const Aside = styled("aside", {
  shouldForwardProp: (prop) => prop !== "menuSlideIn",
})(({ theme, menuSlideIn }) => ({
  paddingLeft: menuSlideIn ? 0 : "1.5em",
  paddingRight: menuSlideIn ? 0 : "1.5em",
  paddingTop: "8em",
  paddingBottom: "15em",
  height: "100vh",
  boxShadow: "5px -5px 7px #eee",
  position: "fixed",
  overflowY: "auto",
  overflowX: "hidden",
  zIndex: theme.zIndex.appBar + 1,
  background: theme.palette.secondary.main,
  transition: "all .25s ease-in-out",
  width: !menuSlideIn ? 270 : 0,

  "& .MuiListItemButton-root": {
    marginBottom: "1em",
    paddingLeft: menuSlideIn ? 0 : 16,
    paddingRight: menuSlideIn ? 0 : 16,
    justifyContent: menuSlideIn ? "center" : "flex-start",

    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: 5,
    },

    [theme.breakpoints.down("md")]: {
      display: menuSlideIn ? "none" : "flex",
    },
  },

  "& .menu-icon": {
    color: "#fff",
  },

  "& .MuiListItemIcon-root": {
    minWidth: menuSlideIn ? "auto" : 50,
  },

  "& .MuiTypography-root": {
    fontSize: "1.5rem",
    color: "#fff",
  },

  "& .MuiListItemButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: ".5rem",

    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },

    "& .MuiTypography-root": {
      color: "#fff",
    },
  },

  "&::-webkit-scrollbar": {
    width: ".85rem",
  },

  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 1rem rgba(0, 0, 0, 0.2)",
  },

  "&::-webkit-scrollbar-thumb": {
    borderRadius: ".5rem",
    background: theme.palette.secondary.dark,
  },

  [theme.breakpoints.only("xs")]: {
    paddingTop: "10rem",
    zIndex: 1,
    width: menuSlideIn ? 0 : "80%",
    paddingLeft: menuSlideIn ? 0 : "1.5rem",
    paddingRight: menuSlideIn ? 0 : "1.5rem",
  },
}));
